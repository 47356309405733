const DoorEntrance = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_3055_2372)">
      <path
        d="M2.5 8.75027C2.5 4.60814 5.85786 1.25027 10 1.25027V1.25027C14.1421 1.25027 17.5 4.60814 17.5 8.75027V18.7503H2.5V8.75027Z"
        stroke="#444444"
        strokeLinejoin="round"
      />
      <path d="M10 1.25027V18.7503" stroke="#444444" />
      <path d="M12.5 9.37527V11.2503" stroke="#444444" strokeLinecap="round" />
      <path d="M7.5 9.37527V11.2503" stroke="#444444" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_3055_2372">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.000274658)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default DoorEntrance;
