const Timer = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.3334 3.12527C3.3334 6.99127 6.46741 10.1253 10.3334 10.1253C14.1994 10.1253 17.3334 6.99127 17.3334 3.12527H16.0834C16.0834 6.30091 13.509 8.87527 10.3334 8.87527C7.15777 8.87527 4.5834 6.30091 4.5834 3.12527H3.3334Z"
      fill="#444444"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.58337 2.50027C1.58337 2.1551 1.8632 1.87527 2.20837 1.87527H18.4584C18.8036 1.87527 19.0834 2.1551 19.0834 2.50027C19.0834 2.84545 18.8036 3.12527 18.4584 3.12527H2.20837C1.8632 3.12527 1.58337 2.84545 1.58337 2.50027Z"
      fill="#444444"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.3334 17.1249C3.3334 17.1248 3.3334 17.125 3.3334 17.1249C3.3334 13.2589 6.46741 10.1253 10.3334 10.1253C14.1994 10.1253 17.3334 13.2585 17.3334 17.1245C17.3334 17.1244 17.3334 17.1247 17.3334 17.1245L16.0834 17.1249C16.0834 17.1248 16.0834 17.125 16.0834 17.1249C16.0834 13.9493 13.509 11.3745 10.3334 11.3745C7.15777 11.3745 4.5834 13.9489 4.5834 17.1245C4.5834 17.1244 4.5834 17.1247 4.5834 17.1245L3.3334 17.1249Z"
      fill="#444444"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.58337 17.7499C1.58337 18.0951 1.8632 18.3749 2.20837 18.3749H18.4584C18.8036 18.3749 19.0834 18.0951 19.0834 17.7499C19.0834 17.4047 18.8036 17.1249 18.4584 17.1249H2.20837C1.8632 17.1249 1.58337 17.4047 1.58337 17.7499Z"
      fill="#444444"
    />
  </svg>
);

export default Timer;
