const Users = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M14.1666 17.5003V15.8336C14.1666 14.9496 13.8155 14.1017 13.1903 13.4766C12.5652 12.8515 11.7174 12.5003 10.8333 12.5003H4.16665C3.28259 12.5003 2.43474 12.8515 1.80962 13.4766C1.1845 14.1017 0.833313 14.9496 0.833313 15.8336V17.5003"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.50002 9.16694C9.34097 9.16694 10.8334 7.67456 10.8334 5.83361C10.8334 3.99266 9.34097 2.50027 7.50002 2.50027C5.65907 2.50027 4.16669 3.99266 4.16669 5.83361C4.16669 7.67456 5.65907 9.16694 7.50002 9.16694Z"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1667 17.5003V15.8336C19.1661 15.0951 18.9203 14.3776 18.4678 13.7939C18.0153 13.2102 17.3818 12.7933 16.6667 12.6086"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 2.60861C14.0503 2.7922 14.6858 3.2092 15.1397 3.79387C15.5935 4.37855 15.8398 5.09764 15.8398 5.83778C15.8398 6.57792 15.5935 7.29701 15.1397 7.88169C14.6858 8.46636 14.0503 8.88336 13.3333 9.06695"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Users;
