const Timing = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <path
      stroke="#444"
      d="M10 18.333a8.333 8.333 0 1 0 0-16.666 8.333 8.333 0 0 0 0 16.666Z"
    />
    <path stroke="#444" d="M9.375 5v5l5 2.5" />
  </svg>
);
export default Timing;
