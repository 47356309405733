const UnescoStatus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.0001 1.4993C5.27299 1.4993 1.44092 5.33137 1.44092 10.0585C1.44092 14.2995 4.52548 17.8202 8.57356 18.4993V14.3381L5.47659 11.2411C5.08607 10.8506 5.08607 10.2174 5.47659 9.82687L9.29298 6.01048C9.68351 5.61996 10.3167 5.61996 10.7072 6.01048L14.5236 9.82687C14.9141 10.2174 14.9141 10.8506 14.5236 11.2411L11.4266 14.3381V18.4993C15.4747 17.8202 18.5593 14.2995 18.5593 10.0585C18.5593 5.33137 14.7272 1.4993 10.0001 1.4993Z"
      stroke="#444444"
      strokeLinejoin="round"
    />
  </svg>
);

export default UnescoStatus;
