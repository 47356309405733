const ArchitectureStyle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path d="M1.875 18.1253H18.125" stroke="#444444" strokeLinecap="round" />
    <path
      d="M2.5 17.5003V10.0003L8.125 5.62524L13.125 10.0003V17.5003"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 5.62527V2.50027H17.4999V17.5003"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.25 17.7503V15.2503C9.25 14.4218 8.57843 13.7503 7.75 13.7503V13.7503C6.92157 13.7503 6.25 14.4218 6.25 15.2503V17.7503"
      stroke="#444444"
    />
    <path
      d="M2.5 10.0003V7.50027"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.125 10.6253V7.50027"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArchitectureStyle;
