const WaitTime = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M8.95215 0.501251C13.6466 0.501251 17.4522 4.30683 17.4522 9.00125C17.4522 13.6957 13.6466 17.5013 8.95215 17.5013C6.4667 17.5013 4.23041 16.4345 2.67615 14.7339M8.84588 3.9012V9.0012L5.81397 12.1888"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.00146 0.500275C4.30704 0.500275 0.501465 4.30585 0.501465 9.00027C0.501465 11.2638 1.38626 13.3208 2.82887 14.844"
      stroke="#444444"
      strokeLinecap="round"
      strokeDasharray="1.25 2.5"
    />
  </svg>
);

export default WaitTime;
