const WaitTimeFast = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5.625 9.85321H1.25M4.375 12.2062H2.5M5 7.50027H1.875"
      stroke="#444444"
      strokeLinecap="round"
    />
    <path
      d="M5 4.1967C6.46545 2.76068 8.47248 1.87527 10.6863 1.87527C15.1736 1.87527 18.8113 5.51296 18.8113 10.0003C18.8113 14.4876 15.1736 18.1253 10.6863 18.1253C8.47248 18.1253 6.46545 17.2399 5 15.8038"
      stroke="#444444"
      strokeLinecap="round"
    />
    <path
      d="M10.625 5.62527V10.4253L14.375 12.5003"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WaitTimeFast;
