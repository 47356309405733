const AddressIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_3055_2295)">
      <path
        d="M17.5 8.33362C17.5 14.167 10 19.167 10 19.167C10 19.167 2.5 14.167 2.5 8.33362C2.5 6.34449 3.29018 4.43684 4.6967 3.03032C6.10322 1.62379 8.01088 0.833618 10 0.833618C11.9891 0.833618 13.8968 1.62379 15.3033 3.03032C16.7098 4.43684 17.5 6.34449 17.5 8.33362Z"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10.8336C11.3807 10.8336 12.5 9.71433 12.5 8.33362C12.5 6.95291 11.3807 5.83362 10 5.83362C8.61929 5.83362 7.5 6.95291 7.5 8.33362C7.5 9.71433 8.61929 10.8336 10 10.8336Z"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3055_2295">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.000274658)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default AddressIcon;
